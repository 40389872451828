
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(248, 250, 248, 1); /* Schimbat opacitatea la 1 pentru a elimina transparența */
  height: 50px;
  padding: 0 20px;
  width: 100%;
  z-index: 1000;
  margin-bottom: 2px;
  position: fixed; /* Navbar-ul va fi fixat în partea de sus */
  top: 0;
  left: 0;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: blue;
  font-family: "Roboto", sans-serif; /* Am schimbat fontul la Roboto */
}

.logo img {
  width: 120px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  box-sizing: none;
  margin: 0 auto;
}

.navList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navList li {
  margin-left: 10px;
}

.navLinks {
  color: #423f3f;
  text-decoration: none;
  padding: 5px 10px;
  font-family: "Roboto", sans-serif; /* Am schimbat fontul la Roboto */
}

.navLinks:hover {
  color: #5697d0;
}

.navMenu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 30px;
}

.navLinks {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif; /* Am schimbat fontul la Roboto */
  font-weight: 400;
  color: #2E3192;
  text-decoration: none;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.navItem {
  line-height: 40px;
  margin-right: 1rem;
  position: relative; /* Necessary for the dropdown positioning */
}

.navItem:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.navItem:hover:after {
  width: 100%;
  background: #ffdd40;
}

.navItem.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.navIcon {
  display: none;
}

.mainContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.dropdownMenu {
  position: absolute;
  top: 46px;
  left: 0;
  background-color: rgba(248, 250, 248, 0.9);
  display: flex;
  flex-direction: column;
  width: 150px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  padding-left: 10px;
}

.dropdownMenu li {
  width: 100%;
  text-align: left;
}

.dropdownLink {
  
  color: #2E3192;
  padding: 8px 3px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/* .dropdownLink:hover {
  background-color: #f1f1f1;
} */


@media screen and (max-width: 960px) {

  .dropdownMenu {
    
    width: 100%;}
  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 30px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navMenu.active {
    background: rgba(248, 250, 248, 0.9);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navItem.active {
    color: #ffdd40;
    border: none;
  }

  .navLinks {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navIcon {
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffdd40;
    margin-right:35px;
  }
}
