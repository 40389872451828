.container {
  padding: 20px;
  display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.latestArticle {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 80px;
  max-width: 1200px;
  margin-left: 30px;
}

.latestArticleImage {
  width: 50%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  min-width: 250px;
}

.latestArticleContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  position: relative;
}

.latestArticleTitle {
  font-size: 32px;
  margin: 0 0 10px 0;
  color: #333333c7;
}

.latestArticleContent {
  margin-bottom: 10px;
}

.meta {
  right: 0;
  top: 0;
  width: 100%;
  text-align: right;
  color: #555;
  font-size: 16px;
}

.authorDate {
  margin-top: 20px;
}

.readMore {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
}

.viewMoreArticles {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 959px) {
  .latestArticle {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .latestArticleImage {
    /* width: 100%;
    max-height: 300px; Ajustează înălțimea imaginii pentru ecrane mai mici */
  }

  .latestArticleContentWrapper {
    width: 100%;
    position: static; /* Îndepărtează poziționarea absolută pentru mobil */
    text-align: center;
  }
  

  .latestArticleTitle {
    font-size: 28px;
  }

  .meta {
    text-align: center; /* Center text on mobile */
  }

  .authorDate {
    margin-top: 10px;
  }

  .readMore, .viewMoreArticles {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .latestArticleTitle {
    font-size: 24px;
  }

  .latestArticleImage {
    /* max-height: 200px; Ajustează înălțimea imaginii pentru telefoane mai mici */
  }

  .latestArticleContentWrapper {
    padding: 0 10px;
  }
}
