.container {
    padding: 30px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .title {
    font-size: 26px;
    margin-bottom: 20px;
    color: #333333c7;
  }
  
  .articleImage {
    width: 100%;
    max-width: 600px; /* Adjust width to fit your design */
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer; /* Indică utilizatorilor că imaginea este clicabilă */
  }
  
  .content {
    margin-bottom: 20px;
  }
  
  .meta {
    font-size: 16px;
    color: #555;
    margin-top: 20px;
    flex-direction: row-reverse;
  }
  
  .meta p {
    margin: 5px 0;
  }
  
  .additionalPhoto {
    width: 300px; /* Adjust width to make images smaller */
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer; /* Indică utilizatorilor că imaginea este clicabilă */
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .modal:hover {
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalImage {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: bold;
  }
  
  .closeButton:hover {
    color: #f8f8f6; /* Change color on hover */
  }
  
  .modal img {
    max-width: 90%;
    max-height: 80%;
  }
  