.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  z-index: 1000;
  min-width: 300px; /* Updated for better mobile experience */
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
  margin-top: 50px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.spaceApplication {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.form {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.formGroup {
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.formGroup input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.formGroup span.error {
  color: red;
  font-size: 0.875em;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.submitButton {
  padding: 10px 15px;
  border: none;
  background-color: rgb(199 197 182);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.submitButton:disabled {
  background-color: #cccccc;
}

.rulesButton {
  background: none;
  border: none;
  color: #a46767;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.rulesContainer {
  padding: 20px;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

.closeButton {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

/* Media queries for responsiveness */
@media (max-width: 767px) {
  .modal {
    max-width: 390px;
  }

  .formGroup input,
  .formGroup textarea {
    max-width: 280px;
  }

  .container {
    padding: 15px;
  }

  .submitButton {
    padding: 8px 12px;
  }

  .formGroup label {
    font-size: 0.875em;
  }

  .formGroup input,
  .formGroup textarea {
    padding: 6px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .modal {
    padding: 20px;
    min-width: 320px;
  }

  .container {
    padding: 20px;
  }

  .submitButton {
    padding: 10px 15px;
  }

  .formGroup label {
    font-size: 1em;
  }

  .formGroup input,
  .formGroup textarea {
    padding: 8px;
  }
}

@media (min-width: 1024px) {
  .modal {
    padding: 25px;
    min-width: 360px;
    margin-top: 50px;
  }

  .container {
    padding: 25px;
  }

  .submitButton {
    padding: 12px 18px;
  }

  .formGroup label {
    font-size: 1.125em;
  }

  .formGroup input,
  .formGroup textarea {
    padding: 10px;
  }
}
