.container {
  padding: 20px;
  max-width: 1200px;
  margin-top: 70px;

}

.articlesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between cards */
}

.articleCard {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.articleImage {
  width: 100%; /* Full width on cards */
  height: auto;
  object-fit: cover;
}

.articleTitle {
  font-size: 20px;
  margin: 10px 0 5px; /* Adjust margins */
}

.articleExcerpt {
  font-size: 14px;
  margin: 5px 0;
}

.readMore {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.articleMeta {
  font-size: 12px;
  color: #555;
  margin-top: 5px; /* Add margin for spacing */
}

/* Mobile and tablet styles */
@media (max-width: 768px) {
  .articlesGrid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

@media (max-width: 480px) {
  .articlesGrid {
      grid-template-columns: 1fr; /* 1 column on mobile */
  }

  .articleTitle {
      font-size: 18px;
  }

  .articleExcerpt {
      font-size: 12px;
  }

  .readMore {
      font-size: 12px;
  }

  .articleMeta {
      font-size: 12px;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pageButton {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.pageButton:hover {
  background-color: #0056b3;
}

.pageButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.active {
  background-color: #0056b3;
}
