@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  border-bottom: none;
 padding: 0px;
  list-style-type: none;
  
}

.footer .container {
  background-color: #ECF2FD;
  color: #473b3bc7;
  font-size: 14px;
  padding: 30px 0px 30px 0px;
  margin: 0 auto;
  
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  max-width: 170px;
  margin-left: 30px;
  flex: 1;
  min-width: 150px;
}

.column {
  max-width: 500px;
  margin-left: 70px;
  min-width: 350px;
}

.footer h3 {
  font-size: 16px;
  margin-bottom: 15px;
  color: #473b3bc7;
  margin-top: 0px;
}

.list {
  list-style: none;
  padding: 0;
}

.list li {
  margin-bottom: 10px;
}

.list a {
  color: #473b3bc7;
  text-decoration: none;
}

.list a:hover {
  text-decoration: underline;
}

.socialIcons {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.socialIcons li {
  display: inline;
}

.socialIcons a {
  color: #473b3bc7;
  font-size: 20px;
  /* margin-left: 10px; */
}

.socialIcons a:hover {
  color: #1a0ec4;
}

.icon {
  margin-right: 10px;
}

.formSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailInput {
  padding: 10px;
  border: 1px solid #394047;
  border-radius: 7px;
  font-size: 16px;
  width: 250px;
  margin-right: 10px;
  background-color: #cccccc05;
}

.subscribeBtn {
  background: #473b3ba3;
  color: #473b3b;
  border: 1px solid;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  transition: background 0.3s;
  cursor: pointer;
  position: relative;
}

.subscribeBtn:hover {
  background-color: #999b74;
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 6px #c8cacb;
}
.navLinks{
  color: #473b3bc7;
}


/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .container {
    padding: 0 10px;
  }

  .col, .column {
    max-width: 170px;
    margin-left: 20px;
  }
}

@media (max-width: 992px) {
  .footer {
    /* padding: 30px 0; */
  }

  .col, .column {
    min-width: 150px;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .footer {
    /* padding: 20px 0; */
  }
  .footer .container {
   
    padding: 10px 0px 0px 0px;
   
    
  }

  .row {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .col, .column {
    min-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .emailInput {
    width: 200px;
    margin-right: 5px;
  }
}

@media (max-width: 576px) {
  .footer {
    /* padding: 10px 0; */
  }

  .container {
    padding: 0 5px;
  }

  .emailInput {
    width: 150px;
    font-size: 14px;
  }

  .subscribeBtn {
    font-size: 14px;
    padding: 8px 15px;
  }
}
