.container {
      /* display: flex; */
      flex-direction: column;
      /* background-color: #ecf2fd; */
      font-family: 'Roboto', sans-serif;
      color: #333333;
      /* width: 100%; */
      padding: 40px;
      max-width: 1400px;
}

.textCenter {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.sectionHeading {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 20px 0 0;
  color: #5e624a;
  margin-top: 50px;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #333333c7;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.sectionSubheading {
  font-size: 1.25rem;
  font-weight: 300;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
}

.contactContainer {
  border-radius: 30px; 
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', sans-serif;
}

.contactWrapper {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.infoItem i {
  font-size: 1.5rem;
  margin-right: 10px;
}

.infoItem span {
  font-weight: 700;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
}

.infoItem p {
  display: block;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
}

.contactFormWrapper {
  width: 45%;
  font-family: 'Roboto', sans-serif;
}

.contactForm {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
}

.formColumn {
  flex: 1;
  min-width: 200px;
  margin-right: 20px;
  font-family: 'Roboto', sans-serif;
}

.formGroup {
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.formControl {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: none;
  background-color: #ecf0f3;
  box-shadow: 5px 5px 10px rgba(166, 180, 200, 0.7), -5px -5px 11px #fff;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
}

.formControl:hover {
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 11px #fff;
}

textarea.formControl {
  height: 150px;
  resize: none;
  font-family: 'Roboto', sans-serif;
}

.submitButton {
  background: #ecf0f3;
  color: #473b3b;
  border: 0.5px solid rgb(171, 186, 205);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  transition: background 0.3s;
  cursor: pointer;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.submitButton:hover {
  background-color: #99a4c5;
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 11px #c8cacb;
}

.containerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 768px) {
  .contactContainer {
    flex-direction: column;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
  }

  .contactWrapper, .contactFormWrapper {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    
  }

  .sectionHeading {
    /* font-size: 2rem; */
    font-family: 'Roboto', sans-serif;
  }

  .sectionSubheading {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
  }

  .formRow {
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
  }

  .formColumn {
    margin-right: 0;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
}
.downloadLinks{
  margin-left: 40px;
  margin-bottom: 20px;
  line-height: 1.6;
}