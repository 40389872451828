

.footer {
  background-color: #535359;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  margin: 0 auto;
    
}

.containerFooter {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px; 
}

.copyright {
  display: inline-block;
  margin-right: 20px;
}

.designed {
  display: inline-block;
}

.designed a {
  color: #fff;
  text-decoration: none;
}

.designed a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {
  .container {
    padding: 0 10px; 
  }
  
  .copyright, .designed {
    display: block;
    margin: 10px 0; 
    text-align: center;
  }
}


@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    padding: 0 15px; 
  }
  
  .copyright, .designed {
    display: block;
    margin: 10px 0;
    text-align: center;
  }
}
