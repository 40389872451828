/* Partners.module.css */

.container {
  padding: 10px;
  /* background-color: #f9f9f9; */
  margin-top: 40px;
  margin-left: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #333333c7;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .grid {
 
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  
  .item {
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 150px;
  }
  
  .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    /* transition: transform 0.3s ease; */
  }
  
  .item:hover img {
    /* transform: scale(1.05); */
  }
  