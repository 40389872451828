.container {
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    color: #333333;
    padding: 40px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .textCenter {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .sectionHeading {
    font-size: 28px;
    font-weight: 700;
    color: #333333c7;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  
  .sectionSubheading {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 10px;
  }
  
  .cardGrid {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: nowrap; /* Prevent wrapping on desktop */
  }
  
  .card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 250px;
    text-align: center;
    transition: transform 0.3s;
    height: 300px;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .cardImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    max-width: 250px;
  }
  
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    padding: 15px;
    display: none;
  }
  
  @media (max-width: 1024px) { /* For tablet and mobile */
    .cardGrid {
      flex-wrap: wrap; /* Allow wrapping on tablet and mobile */
    }
  }
  
  @media (max-width: 768px) { /* For mobile */
    .container {
      padding: 20px;
    }
  
    .sectionHeading {
      font-size: 24px;
      margin-top: 50px;
    }
  
    .sectionSubheading {
      font-size: 1rem;
    }
  
    .cardGrid {
      gap: 15px;
    }
  
    .card {
      width: 100%;
      max-width: 250px;
      height: 300px;
    }
  }
  