

.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 300px; 
  margin: auto;
  overflow: hidden;
  position: relative;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; 
}

.slider::before,
.slider::after {
  content: "";
  height: 150px;
  position: absolute;
  width: 150px; 
  z-index: 2;
  
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slideTrack {
  display: flex;
  width: calc((150px + 30px) * 7); 
  animation: scroll 40s linear infinite;
}

.slide {
  height: 150px;
  width: 150px;
  margin-right: 30px; 
  flex-shrink: 0; 
}

.slide img {
  width: 100%;
  height: 100%;

}
.title{
  font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #333333c7;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Move by the width of the track */
  }
}
