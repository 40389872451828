:global {
  * {
    font-family: 'Roboto', sans-serif;
  }

  .slick-slider {
    margin: 0 auto;
    width: 80%;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
}

/* Local styles for the testimonial component */
.testimonialSection {
  margin: auto;
    /* padding: 0 1rem; */
    max-width: 1100px;
    text-align: center;
    /* background-color: #ECF2FD; */
    /* padding: 40px;*/
}
.container {
  margin: auto;
 
  /* max-width: 1100px; */
  
  background: linear-gradient(to bottom, #FFFFFF, #ECF2FD);
  padding: 40px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  color: #2E3192;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.testimonial {
  margin: 0 1rem;
  min-width: 300px;
  width: 100%;
display: inline-block;

border-radius: 25px;


}

.headTestimonial {
  margin: 1.75rem auto;
  width: 150px;
  height: 150px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
}

.bodyTestimonial {
  padding: 2.5rem;
}

.nameTestimonial {
  font-size: 1.5rem;
  color: rgb(50, 50, 50);
  margin-bottom: 0.25rem;
}

.roleTestimonial {
  color: red;
  font-style: italic;
}

.descTestimonial {
  line-height: 1.5rem;
  letter-spacing: 1px;
  color: rgb(150, 150, 150);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonial {
    min-width: auto; /* Adjust for smaller screens */
  }
  .title {
    font-size: 24px;
  }
}

