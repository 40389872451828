
.team {
  font-family: 'Roboto', sans-serif;
  color: #473b3b;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1400px;
}

.teamTitle {
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.heading {
  margin-top: 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #333333c7;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  margin-top: 0px;
}

.teamMembers {
  display: flex;
  justify-content: center;
  padding: 0px 30px;
  border-radius: 10px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.boardButton{
  padding: 15px 25px;
    font-size: 1.1rem;
    font-weight: 700;
    color: #2E3192;
    border: none;
    border-radius: 30px;
    box-shadow: 5px 5px 15px #b7b7a7, -5px -5px 10px rgb(199, 197, 182);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin: 10px;
    background-color: #FBED21;
    background-color: #ecf0f3;
    box-shadow: 5px 5px 10px rgba(166, 180, 200, 0.7), -5px -5px 11px #fff;
}
.boardButton:hover {
  
  background-color: #99a4c5;
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 11px #c8cacb;
}

.teamMember {
  width: 100%;
  max-width: 250px; 
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transform: scale(0.8, 0.8);
  padding: 20px;
  transition: all 1s ease-in-out;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.teamMember:hover {
  opacity: 1;
  transform: scale(1, 1);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.pic {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  overflow: hidden;
}

.pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  margin: 10px 0 5px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

.position {
  color: #473b3b;
  font-size: 18px;
}


@media screen and (min-width: 1200px) {
  .teamMembers {
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between;  */
  }

  .teamMember {
    width: calc(25% - 30px); 
    margin: 15px; 
  }
}


@media screen and (min-width: 768px) and (max-width: 1199px) {
  .teamMembers {
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* width: 1000px; */
  }
  .heading {
    
    font-size: 24px;}

  .teamMember {
    width: calc(33.33% - 30px); 
    margin: 15px;
  }
}


@media screen and (max-width: 767px) {
  .teamMembers {
    flex-direction: column;
    align-items: center;
  }

  .teamMember {
    width: calc(100% - 30px); 
    margin: 15px 0;
  }
  .heading {
    
    font-size: 24px;}
}
