.container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .heading {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #333333c7;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
  }
  
  .card {
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    height: 200px;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .image {
    height: 200px;
    object-fit: cover;
    width: 200px;
  }
  
  .title {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    background-color: #f9f9f9;
  }
  @media (max-width: 768px) {
    .heading {
      font-size: 24px;
    }
  }