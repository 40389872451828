@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 80px;
  position: relative;
  margin: 0 auto;
  max-width: 1300px;
  background-color: #ECF2FD;
  margin-top: 50px;
}

.containerSpace {
  background-color: #ECF2FD;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.leftImageContainer {
  display: flex;
  justify-content: center;
  flex: 1;
}

.image {
  width: 80%;
  height: 550px;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 6);
  transition: box-shadow 0.3s ease;
}

.content {
  flex: 1;
  box-sizing: border-box;
  margin-right: 25px;
}

.conName {
  font-size: 28px;
  font-weight: 700;
  color: #2E3192;
  font-family: 'Roboto', serif;
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.icon {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.iconImage {
  font-size: 35px;
  color: #ffdd40;
  border-radius: 50%;
  border: 3px solid #ffdd40;
  padding: 10px;
  background-color: white;
  margin-right: 15px;
}

.iconText {
  display: flex;
  flex-direction: column;
}

.iconText h2 {
  font-size: 20px;
  font-weight: 700;
  color: #2E3192;
  margin: 0 0 5px 0;
  font-family: 'Roboto', serif;
}

.iconText p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0px 20px;
  }

  .leftImageContainer {
    order: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .image {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }

  .content {
    order: 2;
    margin-right: 0;
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0px 20px;
  }

  .conName {
    font-size: 24px;
  }

  .leftImageContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .image {
    height: auto;
    margin-top: 30px;
  }

  .content {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0px 20px;
  }

  .leftImageContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .content {
    margin-right: 0;
    margin-left: 0px;
  }

  .icons {
    align-items: center;
  }

  .icon {
    margin-bottom: 20px;
  }

  .iconImage {
    font-size: 30px;
    padding: 8px;
  }

  .iconText h2 {
    font-size: 20px;
  }

  .iconText p {
    font-size: 14px;
  }
}

.section {
  padding: 60px 0;
  margin: 0;
  position: relative;
  z-index: 1;
}
