@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* Spațiu */
.space {
  font-family: 'Roboto', sans-serif;
    padding: 40px 0;
    text-align: center;
    /* background-color: #ECF2FD; */
    /* width: 100%; */
    margin: 0 auto;
    margin: auto;
    padding: 0 1rem;
    /* max-width: 1100px; */
    text-align: center;
    background: linear-gradient(to bottom, #FFFFFF, #ECF2FD);
    padding: 40px;
   
}

.space h2 {
  font-size: 28px;
    font-weight: 700;
    color: #2E3192;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.space p {
  font-family: 'Roboto', sans-serif; /* Aplică fontul Roboto */
  font-size: 1.25rem;
  font-weight: 300;
  color: #000;
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  padding: 15px 25px;
  font-size: 1.25rem;
  font-weight: 700;
  color: #2E3192;
  border: none;
  border-radius: 30px;
  box-shadow: 5px 5px 15px #b7b7a7, -5px -5px 10px rgb(199, 197, 182);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 10px;
  background-color: #FBED21;
  background-color: #ecf0f3;
  box-shadow: 5px 5px 10px rgba(166, 180, 200, 0.7), -5px -5px 11px #fff;
  width: 280px;

  
}

.button:hover {
  /* background-color: #6b92e5;; /* Culoare galbenă la hover */
  /* box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 6px #d8e990; */ 
  background-color: #99a4c5;
  box-shadow: inset 5px 5px 10px rgba(166, 180, 200, 0.7), inset -5px -5px 11px #c8cacb;
}

/* Media queries pentru ecrane mai mici */
@media (max-width: 768px) {
  .button {
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .space h2 {
    font-size: 24px;
  }

  .space p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}


@media (min-width: 1025px) {
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}